<template>
    <div class="update-row">
        <div class="modal">
            <div class="content">
                <div class="download-container">
                    <label> {{toLocal('updates.new_training_updates')}} <strong>({{ getSizeLabel($store.state.totalToDownload) }})</strong></label>
                    <button @click="downloadFiles" :class="{inactive: downloading || finished}">
                        {{toLocal('updates.update_now')}}
                        <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.242157 7.86983C0.0859476 7.69626 0.0859475 7.41485 0.242157 7.24129L3.15931 4L0.242157 0.758714C0.0859472 0.585148 0.0859472 0.303741 0.242157 0.130175C0.398367 -0.0433912 0.651633 -0.0433913 0.807843 0.130175L4.00784 3.68573C4.16405 3.8593 4.16405 4.1407 4.00784 4.31427L0.807843 7.86983C0.651633 8.04339 0.398367 8.04339 0.242157 7.86983Z" fill="white"/>
                        </svg>

                        <!-- <svg  width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-06d6119b="" d="M11 1V12M11 12L7 8.82692M11 12L15 8.82692" stroke="#7D7D7D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path data-v-06d6119b="" d="M1 8V15C1 16.1046 1.89543 17 3 17H19C20.1046 17 21 16.1046 21 15V8" stroke="#7D7D7D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg> -->
                    </button>
                </div>
                <div class="progress-container" v-if="!finished && downloading">
                    <div class="progress-bar">
                        <span 
                            :style="{
                                width: progress > 100 ? '100%' : progress + '%',
                            }"
                        >
                        </span>
                    </div>
                    <div class="progress-labels">
                        <span>
                            {{  downloadedBytes > $store.state.totalToDownload ? getSizeLabel($store.state.totalToDownload) : getSizeLabel(downloadedBytes) }}
                        </span>
                        <span>
                            {{  getSizeLabel($store.state.totalToDownload) }}
                        </span>
                    </div>
                </div>
                <div v-else-if="finished" class="finished-downloading">
                    <span>{{ toLocal('updates.files_downloaded') }}</span>
                    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5153 0.216262C16.0313 0.592966 16.1548 1.33175 15.7913 1.86638L10.2214 10.0574L10.2214 10.0574L7.20223 14.4978C6.99391 14.8042 6.65792 14.9903 6.29634 14.9996C5.93475 15.0089 5.59026 14.8403 5.36752 14.545L0.24245 7.75042C-0.146232 7.23512 -0.0581768 6.49089 0.439127 6.08815C0.93643 5.6854 1.65466 5.77664 2.04334 6.29194L6.22039 11.8297L8.35292 8.69331L8.35295 8.69327L13.9228 0.502218C14.2864 -0.0324148 14.9994 -0.160441 15.5153 0.216262Z" fill="#49B357"/>
                    </svg>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            totalToDownload: 0,
            downloadedBytes: 0,
            progress: 0,
            downloading: false,
            finished: false,
        }
    },

    mounted() {
        if(window.ReactNativeWebView) {
            window.addEventListener("message",  this.setWebViewData); //android 
            document.addEventListener("message", this.setWebViewData); //ios
        } 
    },
    beforeDestroy() {
        if(window.ReactNativeWebView) {
            window.removeEventListener("message",  this.setWebViewData); //android 
            document.removeEventListener("message", this.setWebViewData); //ios
        }
    },
    methods: {
        setWebViewData(data) {
           
            if (data.data.startsWith('downloadedBytes:')) {
                let bytes = data.data.replace('downloadedBytes:', '');
                this.downloadedBytes = bytes;
                this.progress = Math.round((this.downloadedBytes / this.$store.state.totalToDownload) * 100);
            }
            if (data.data.startsWith('finishedDownloadingFiles')) {
                this.downloading = false;
                this.finished = true;
                this.saveFilesDownloaded();
                setTimeout(() => {
                    this.$store.state.checkAutoUpdate = false;
                    this.$store.state.showUpdateIndicators = false;
                    this.$store.state.totalToDownload = 0;
                    this.$store.state.filesToDownload = [];
                    this.$store.state.downloadableFiles = [];
                },3000);
            }
        },
        saveFilesDownloaded() {
            let files = this.$store.state.filesToDownload;
            files = files.map(file => file.url);
            axios.post(`${this.$store.state.api_link}/save-downloaded-files`, {
                    files: files
                }, this.$store.getters.axiosConfig())  
        },
        downloadFiles() {
            if (this.downloading || this.finished) return;
            let files = this.$store.state.filesToDownload;
            files = files.map(file => `${this.$store.state.api_uploads_link}${file.url}`);
            this.downloading = true;
            this.$store.state.searchedFilesInDevice = [];
            this.$store.state.nonExistingDeviceFiles = [];
            this.$store.state.existingDeviceFiles = [];

            window.ReactNativeWebView.postMessage("downloadFiles:" + JSON.stringify(files));
        },
        getSizeLabel(bytes) {
            let sizes = ['B', 'KB', 'MB', 'GB'];
            if (bytes === 0) return '0 B';
            let KB = Math.floor(Math.log(bytes) / Math.log(1024));
            let number = bytes / Math.pow(1024, KB);
            return Number.parseFloat(number).toFixed(2) + ' ' + sizes[KB];     
        },

    }
}
</script>

<style lang="scss" scoped>
.update-row{
    display: flex;
    flex-direction: column;
    width: 100%;
    .modal{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        color: white;
        font-size: 10px;
    }
}
.download-container{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 10px;
    font-weight: 600;

}
.header{
    padding-bottom: 10px;
}
.content{
    width: 100%;
}
button{
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: none;
    padding: 2px 8px;
    font-size: 10px;
    height: 18px;
    color:#333333;
    background-color: white;
    box-shadow: 0 1px 2px 0px #0000003a ;
    svg{
        path{
            fill: #333333;
        }
    }
    &.inactive{
        opacity: 0.6;
        pointer-events: none;
    }
}
// button{
//     display: flex;
//     align-items: center;
//     white-space: nowrap;
//     border-radius: 10px;
//     color: white;
//     border: none;
//     cursor: pointer;
//     font-size: 10px;
//     text-decoration: underline;
//     background: transparent;
//     gap: 8px;
//     svg{
//         path{
//             fill: white;
//             stroke: white;
//         }
//     }
//     &.inactive{
//         opacity: 0.6;
//         pointer-events: none;
//     }
// }

.progress-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    .progress-bar{
        position: relative;
        border-radius: 10px;
        background-color: white;
        height: 20px;
        width: 100%;
        overflow: hidden;
        box-shadow: 0 1px 7px 0 #0000004d;
        span{
            position: absolute;
            left: 0;
            top: 0;
            background-color: var(--secondary-color);
            width: 30%;
            height: 100%;
            transition: width 0.3s;
        }
    }
}
.finished-downloading{
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    margin-top: 20px;
    svg{
        path{
            fill: white;
        }
    }
    
}
.progress-labels{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    color: white;
}
</style>