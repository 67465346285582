import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
axios.defaults.withCredentials = true;
import echoInit from "./echo.js";
import router from './router.js'

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    api_link: "",
    api_uploads_link: "",
    api_assets_link: "",
    initial_api_link: "",
    backend_link: "",
    parent_link: "",
    header_enabled: true,
    header: true,
    header_hidden: false,
    language: "lt",
    translations: [],
    system_settings: {
      main_logo: "",
      login_bg: "",
      logo: "",
      primary_color: "",
      secondary_color: "",
      third_color: "",
      firstloginmodal: {},
      firstloginmodalrequired: {},
      loginWithoutPass: false,
      registration: false,
      cookiesModal: true,
      usesPhones: false,
      importUsers: false,
      color_palette: 0,
      billingSettings: {},
      shop_to_admins: 1,
      shop_to_users: 0,
    },
    assignModal: {
      visible: false,
      type: "training",
      id: 0,
    },
    loading: null,
    side_open: "",
    side_info: {
      loaded: false,
    },
    currentTraining: -1,
    currentTrainingRented: false,
    currentTrainingData: null,
    uploadedData: 0,
    clickedOutsideTextEditor: [-1, -1],
    currentError: "",
    alertMessages: [],
    current_menu_item: 2,
    previewMode: false,
    user_checked: false,
    user_id: null,
    user_email: null,
    user_name: null,
    user_username: null,
    user_last_name: null,
    user_role: "user",
    user_permissions: {},
    user_trainings_started: 0,
    user_trainings_ended: 0,
    user_test_average: 0,
    user_terms_accepted: true,
    user_can_notify: null,
    user_can_contact: null,
    user_can_identify: null,
    user_phone: null,
    user_is_new: null,
    user_selected_language: "lt",
    user_cols: {},
    access_token: null,
    unread_chats: [],
    selected_theme: "#ec4d3b",
    certificates: null,
    websockets: null,
    toast: null,
    isLoggedIn: false,
    showingHeaderSearchBar: false,
    stopDrag: false,
    currentVtest: -1,
    groups: [],
    departments: [],
    trainingTest: {},
    trainingTestParent: {},
    selectedTest: {},
    selectedParentTest: {},
    isNative: false,
    testStats: {
      users: [],
      groups: [],
      dates: [],
      questions: [],
    },
    testUserStats: {
      users: [],
      groups: [],
    },
    testUserStatsFilter: 0,
    testUserStatsShowAll: 0,
    testCheckAnswersFilter: {},
    testCheckAnswersFilterItemID: 0,
    userTrainingStatSide: null,
    reportForming: {
      selectedTab: 0,
      selectedFolder: -1,
      selectedItem: -1,
      draggingFromFolder: -1,
      currentlyDraggingItem: -1,
      expandedFolder: -1,
      movingFolders: true,
      movingItems: true,
      reportFolderList: [],
      searchReportFolderList: [],
      periodicReportTitle: "",
      periodicReportEmails: [],
      buttons: {
        formReportActive: false,
        activateSendingActive: false,
        eyeIconActive: false,
      },
      periodicReportTiming: {
        date: "",
        formReportAfter: 0,
        formReportAfterType: 0,
      },
      momentaryReport: {
        date_from: "",
        date_to: "",
        formReportButtonActive: false,
        formedReportActive: false,
        reportList: [],
      },
      reportDeleteModal: {
        item: {},
        folder: {},
        modalOpen: false,
      },
      reportHTML: "",
      reportUrl: "",
      periodicReportHTML: "",
      previewMode: false,
      frequentDomains: [],
      progress: 0,
      reportIsForming: false,
    },
    emailForming: {
      emailFolders: [],
      searchEmailFolders: [],
      movingItems: true,
      currentlyDraggingItem: -1,
      draggingFromFolder: -1,
      deleteModal: {
        item: {},
        folder: {},
        folderList: [],
        modalOpen: false,
      },
      selectedFolder: -1,
      selectedItem: -1,
      signature: "",
    },
    uploadProgress: 0,
    privacy_policy_modal: false,
    window_width: 0,
    selectTestsModeActive: false,
    selectModeSelectedTests: [],
    chatMessagesQueue: [],
    testScores: {
      maxScore: 0,
      minScore: 0,
      testVariations: 0,
    },
    importModal: null,
    infoMessage: null,
    testDrag: {
      currentlyDraggingItem: -1,
      draggingFromFolder: -1,
      movingItems: true,
    },
    homePageSearchInput: "",
    homePageSearchLoaded: false,
    showHomePageSearchInput: true,
    prevHomePageSearchInput: "",
    testDeleteModal: {
      item: {},
      folder: {},
      type: "test",
      modalOpened: false,
    },
    isConnected: true,
    isUserTheOnlyOneEditingTraining: true,
    currentGroupOpen: "",
    isOnlyTrainingGroup: false,
    isTrainingBought: false,
    isShopTraining: false,
    globalModal: { target: "" },
    showingTiptapUploadModal: false,
    ssl: true,
    userColumns: [],
    enabledUserColumns: {},
    enabledUserColumnsArray: [],
    disabledUserColumnsArray: [],
    blacktext: false,
    fromRoute: null,
    routeHistory: [],
    userColsLoaded: false,
    trainingMainContainer: null,
    itemPreview: {
      open: false,
      item: null,
      src: null,
    },
    existingDeviceFiles: [],
    nonExistingDeviceFiles: [],
    searchedFilesInDevice: [],
    checkAutoUpdate: false,
    downloadableFiles: [],
    filesToDownload: [],
    totalToDownload: 0,
    showUpdateIndicators: false,
    connectedLMSes: [],
    managedGroups: [],
    pageRerenderKey: 0,
    currentLMS: '',
    appVersion: '',
  },
  mutations: {
    fetchTranslations(state) {
      axios
        .get(`${state.api_link}/translations`)
        .then((res) => {
          state.translations = res.data.data;
        })
        .catch((err) => console.log(err));
    },
    setAppSettings(state, settings) {
      state.language = settings.language;
      state.ssl = settings.ssl;
      let api_url = settings.api_url;
      let country_cookie = Vue.$cookies.get('user_country');
      if (settings.platforms && country_cookie) {
        if(settings.platforms[country_cookie])
          api_url = settings.platforms[country_cookie];
      }
      state.api_link = `${settings.ssl ? "https" : "http"}://${
        api_url
      }/api`;
      state.api_uploads_link = `${settings.ssl ? "https" : "http"}://${
        api_url
      }/uploads/`;
      state.api_assets_link = `${settings.ssl ? "https" : "http"}://${
        api_url
      }/assets/`;
      state.backend_link = `${settings.ssl ? "https" : "http"}://${
        api_url
      }`;
      state.parent_link = `${settings.ssl ? "https" : "http"}://${
        process.env.VUE_APP_PARENT_LMS_API
        }`;
      state.initial_api_link = api_url;
    },
    setNewUrl(state,backend_url) {
        state.api_link = `${state.ssl ? "https" : "http"}://${
            backend_url
        }/api`;
        state.api_uploads_link = `${state.ssl ? "https" : "http"}://${
            backend_url
        }/uploads/`;
          state.api_assets_link = `${state.ssl ? "https" : "http"}://${
            backend_url
        }/assets/`;
          state.backend_link = `${state.ssl ? "https" : "http"}://${
            backend_url
        }`;
    },
    fetchSystemSettings(state) {
      if(Vue.$cookies.get('user_country') != undefined) {
          state.system_settings.platformCountry = Vue.$cookies.get('user_country');
        }
      axios
        .get(`${state.api_link}/get-settings${state.system_settings && state.system_settings.platformCountry ? '?countrySet=true' : '' }`)
        .then((res) => {
          state.system_settings = res.data;
          if (state.system_settings.color_palette == undefined) {
            state.system_settings.color_palette = 0;
          }
          if (state.system_settings.platformCountry) {
            Vue.$cookies.set('user_country', state.system_settings.platformCountry, '2y');
          }
          else if (Vue.$cookies.get('user_country') != undefined) {
            state.system_settings.platformCountry = Vue.$cookies.get('user_country');
          }
          if (state.system_settings.version) {
            console.log(
              "\n%cLearning Managment System " +
                state.system_settings.version +
                "\n%c© by www.learnkey.lt\n",
              "background: #222; color: #f4dc00; font-size: 24px;font-weight: bold; font-family: Arial;",
              "background: #222; color: #aaa; font-size: 16px; font-family: Arial;"
            );
          }
          // if(state.system_settings.shop_to_admins == undefined){
          //   state.system_settings.shop_to_admins = 1;
          // }
          // if(state.system_settings.shop_to_users == undefined){
          //   state.system_settings.shop_to_users = 0;
          // }
        })
        .catch((err) => console.log(err));
    },
    checkDeviceFiles(state) {
       axios
        .get(
          `${state.api_link}/training_group/user_training_groups`,
          this.getters.axiosConfig()
        )
         .then((response) => {
           let groups = response.data;
           console.log(groups);
           let trainings = groups.map((elem) => {
             let trainings = elem.trainings.map((t) => t.id ? t.id : t);
             
             return trainings  
           });
           trainings = trainings.flat();
           axios
             .post(
               `${state.api_link}/training/get-download-files`,
               {
                 trainings: trainings
               },
               this.getters.axiosConfig()
             )
             .then((response) => {
                let files = response.data.files;
                let file_paths = [];
                files.forEach(file => {
                  file_paths.push(file.url)
                });
               state.downloadableFiles = files;
              //  alert(JSON.stringify(file_paths));
               window.ReactNativeWebView.postMessage("checkDeviceFiles:" + JSON.stringify(file_paths));
             })
             .catch((error) => {});
         })
         .catch((error) => {});
    },
    loadUserInfo(state) {
      axios
        .get(
          `${state.api_link}/user/get-self-info`,
          this.getters.axiosConfig()
        )
        .then((response) => {
          let data = response.data.data;
          let cols = {};
          data.forEach((el, index) => {
            if (el.colValue != null) {
              try {
                el.colValue.value = JSON.parse(el.colValue.value);
                cols[el.col.name] = el.colValue.value;
              } catch (e) {}
            }
          });
          state.user_cols = cols;
          if (window.ReactNativeWebView && cols['cols.autoUpdate']) {
            state.checkAutoUpdate = true;
          }
        });
    },
    initializeUserCols(state) {
      axios
        .get(`${state.api_link}/initialize-user-info`,this.getters.axiosConfig())
        .then((response) => {
          state.userColumns = response.data.cols;
          let enabledColumns = Vue.$cookies.get('enabledColumns');
          if(enabledColumns == undefined || enabledColumns == null)
            enabledColumns = null
          else
            enabledColumns = JSON.parse(enabledColumns);
          // enabledColumns = enabledColumns.map(x => x.value)
          response.data.cols.forEach(col => {
            let index = state.userColumns.findIndex(x => x.value == col.value);
            if (enabledColumns != null) {
              if (enabledColumns.includes(col.value)) {
                state.enabledUserColumnsArray.push(col.value)
                if (index != -1)
                  state.userColumns[index].enabled = 1
              }
              else {
                if (index != -1)
                  state.userColumns[index].enabled = 0
              }
            }
            else {
              state.enabledUserColumnsArray.push(col.value)
              if (index != -1)
                state.userColumns[index].enabled = 1
            }
            if(state.enabledUserColumnsArray.includes(col.value))
              state.enabledUserColumns[col.value] = 1
            if (!state.enabledUserColumnsArray.includes(col.value)) {
              state.disabledUserColumnsArray.push(col.value)
            }
          });
          
        })
        .then(() => {
            state.userColsLoaded = true
        })
        .catch((err) => console.log(err));
    },
    openSide(state, side, sideInfo) {
      state.side_open = side.side;
      state.side_info = side.sideInfo;
      state.side_info["loaded"] = false;
    },
    handleError(state, error) {
      console.log(error);
      let message = "";
      console.log(error);
      if (error.hasOwnProperty("message")) {
        state.currentError = error.message;
        message = error.message;
      }
      if (
        error.hasOwnProperty("error") &&
        error.error.hasOwnProperty("response") &&
        error.error.response.hasOwnProperty("data") &&
        error.error.response.data.hasOwnProperty("message")
      ) {
        state.currentError = error.error.response.data.message;
        message = error.error.response.data.message;
      }
      console.log("Error: " + message);
      state.alertMessages.push(message);
      setTimeout(() => {
        const index = state.alertMessages.indexOf(message);
        if (index > -1) {
          state.alertMessages.splice(index, 1);
        }
      }, 6000);
      if ((message == "Token expired" || message == 'Unauthorized') && error.error.response && error.error.response.status == 401) {
        state.loading.loadingStart();
        setTimeout(() => {
    
          router.push({ path: "/login" });

          setTimeout(() => {
            state.loading.loadingDone();
          });
        }, 500);
      }
    },
    newUserStateChange(state, data) {
      state.user_is_new = data;
    },
    setTestUserStats(state, data) {
      state.testUserStats = data;
    },
    setSelectedTest(state, test) {
      state.selectedTest = test;
    },
    setSelectedParentTest(state, test) {
      state.selectedParentTest = test;
    },
    setTestUserStatsFilter(state, id) {
      state.testUserStatsFilter = id;
    },
    async startWebsockets(state) {
      if (state.websockets == null) {
        window.Echo = await echoInit(state, state.backend_link);
        state.websockets = window.Echo;
        window.Echo.connector.pusher.connection.bind("connected", function () {
          state.isConnected = true;
        });

        window.Echo.connector.pusher.connection.bind(
          "disconnected",
          function () {
            state.isConnected = false;
          }
        );
        window.Echo.connector.pusher.connection.bind("failed", (payload) => {
          state.isConnected = false;
        });
        window.Echo.connector.pusher.connection.bind(
          "unavailable",
          (payload) => {
            state.isConnected = false;
          }
        );
      }
    },
    checkTerms(state, getters) {
      axios
        .post(
          `${state.api_link}/user/terms/accepted`,
          {},
          this.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.status == "success") {
            if (response.data.accepted == 0) state.user_terms_accepted = 0;
          } else {
            console.log("Error while loading user's privacy policy info");
          }
        })
        .catch(() => {
          console.log("Error while loading user's privacy policy info");
        });
    },
    getStats(state) {
      axios
        .get(`${state.api_link}/user/stats`, this.getters.axiosConfig())
        .then((res) => {
          state.user_trainings_started = res.data.stats.trainings_started;
          state.user_trainings_ended = res.data.stats.trainings_ended;
          state.user_test_average = res.data.stats.test_average;
        })
        .catch(() => {
          console.log("Error while loading user's stats.");
        });
    },

    setGroups(state, groups) {
      state.groups = groups;
    },
    changeThemeColor(state, colors) {
      state.system_settings.primary_color = colors[0];
      state.system_settings.secondary_color = colors[1];
      state.system_settings.third_color = colors[2];
    },
    userLoggedIn(state) {
      state.isLoggedIn = true;
    },
    setTestStats(state, data) {
      state.testStats = data;
    },
    addPeriodicReportEmail(state, email) {
      state.reportForming.periodicReportEmails.push(email);
    },
  },
  actions: {
    fetchAppSettings({ commit }) {
      // Fetch the settings file and commit the mutation to update the state
      return axios
        .get("/settings.json")
        .then((response) => {
          commit("setAppSettings", response.data);
        })
        .catch((error) => {
          console.error("Failed to fetch settings:", error);
        });
    },
    async fetchSystemSettings({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (Vue.$cookies.get('user_country') != undefined) {
          state.system_settings.platformCountry = Vue.$cookies.get('user_country');
        }
        axios
          .get(`${state.api_link}/get-settings${state.system_settings && state.system_settings.platformCountry ? '?countrySet=true' : ''}`)
          .then((res) => {
            state.system_settings = res.data;
            if (state.system_settings.color_palette == undefined) {
              state.system_settings.color_palette = 0;
            }
            if (state.system_settings.platformCountry) {
              Vue.$cookies.set('user_country', state.system_settings.platformCountry, '2y');
            }
            else if (Vue.$cookies.get('user_country') != undefined) {
              state.system_settings.platformCountry = Vue.$cookies.get('user_country');
            }
            if (state.system_settings.version) {
              console.log(
                "\n%cLearning Managment System " +
                state.system_settings.version +
                "\n%c© by www.learnkey.lt\n",
                "background: #222; color: #f4dc00; font-size: 24px;font-weight: bold; font-family: Arial;",
                "background: #222; color: #aaa; font-size: 16px; font-family: Arial;"
              );
            }
            if (res.data.current_backend) {
              commit('setNewUrl', res.data.current_backend)
              if (res.data.platforms) {
                state.connectedLMSes = res.data.platforms
              }
              state.currentLMS = res.data.current_platform;
            }
            resolve();
            // if(state.system_settings.shop_to_admins == undefined){
            //   state.system_settings.shop_to_admins = 1;
            // }
            // if(state.system_settings.shop_to_users == undefined){
            //   state.system_settings.shop_to_users = 0;
            // }
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          });
      });
    },
  },
  getters: {
    __: (state) => (key) => {
      let result = state.translations.filter(
        (element) => element.translation_key == key
      );
      if (result.length) {
        return result[0][state.language] || key;
      }
      return key;
    },
    axiosConfig: (state) => () => {
      let headers = {
        locale: state.language,
      }
      if (state.isNative) {
        headers['Authorization'] = "bearer " + state.access_token;
      }
      return {
        headers: headers
      };
    },
    axiosConfigDownload: (state) => () => {
      if (state.isNative) {
        return {
          headers: { Authorization: "bearer " + state.access_token },
          responseType: "blob",
        };
      }
      else {
        return {
          responseType: "blob",
        };
      }
    },
    axiosConfigMultipart: (state) => () => {
      let headers = {
        "Content-Type": "multipart/form-data",
      }
      if (state.isNative) {
        headers['Authorization'] = "bearer " + state.access_token;
      }
      return {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          state.uploadedData = progressEvent.loaded;
          state.uploadProgress = parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        },
      };
    },
    
    isSideOpen: (state) => () => {
      return state.side_open != "";
    },
    isCurrentSide: (state) => (side) => {
      return side == state.side_open;
    },
    hasPermission: (state) => (key) => {
      return state.user_role == "admin";
    },
  },
});
