<template>
    <!-- <div class="modal-container" > -->
    <div class="modal-container" v-if="$store.state.filesToDownload.length > 0 && !['settings'].includes($route.name)">
        <div class="modal">
            <div class="content">
                <div class="download-container">
                    <label> {{toLocal('updates.new_training_updates')}}</label>
                    <button @click="goToSettings" >
                        {{toLocal('updates.update_now')}}
                        <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.242157 7.86983C0.0859476 7.69626 0.0859475 7.41485 0.242157 7.24129L3.15931 4L0.242157 0.758714C0.0859472 0.585148 0.0859472 0.303741 0.242157 0.130175C0.398367 -0.0433912 0.651633 -0.0433913 0.807843 0.130175L4.00784 3.68573C4.16405 3.8593 4.16405 4.1407 4.00784 4.31427L0.807843 7.86983C0.651633 8.04339 0.398367 8.04339 0.242157 7.86983Z" fill="white"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div class="bg" v-if="showUpdateBG">
            ddasdasdas
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            filesToDownload: [],
           showUpdateBG: false
        }
    },
    mounted() {
        this.$store.commit('checkDeviceFiles');
        if(window.ReactNativeWebView) {
            window.addEventListener("message",  this.setWebViewData); //android 
            document.addEventListener("message", this.setWebViewData); //ios
        } 
    },
    beforeDestroy() {
        if(window.ReactNativeWebView) {
            window.removeEventListener("message",  this.setWebViewData); //android 
            document.removeEventListener("message", this.setWebViewData); //ios
        }
    },
    methods: {
        goToSettings() {
            this.$router.push('/settings?tab=user#updates');
        },
        setWebViewData(data) {
            if (data.data.startsWith('Background')) {
                this.showUpdateBG = true
                window.ReactNativeWebView.postMessage("showUpdateBG:" + this.showUpdateBG);

            }
            if (data.data.startsWith('filesToDownload:')) {
                
                const message = data.data;
                let files = message.replace('filesToDownload:', '');
                let file_list = JSON.parse(files);
                this.filesToDownload = this.$store.state.downloadableFiles.filter(file => {
                    return file_list.includes(file.url)
                })
                this.$store.state.filesToDownload = this.filesToDownload
                this.$store.state.totalToDownload = this.filesToDownload.reduce((a, b) => a + b.size, 0);
                if (this.$store.state.filesToDownload.length > 0) {
                    this.$store.state.showUpdateIndicators = true
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.modal-container{
    width: 100%;
    z-index: 9999;
    position: absolute;
    color: #000;
    top: 100%;
    left: 0;
    .modal{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #333333;
        color: white;
        height: 18px;
        font-size: 10px;
        box-shadow: 0 4px 6px 0 #00000028;
    }
}
.download-container{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 10px;

}
.header{
    padding-bottom: 10px;
}
button{
    display: flex;
    align-items: center;
    white-space: nowrap;
    border-radius: 10px;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 10px;
    text-decoration: underline;
    background: transparent;
    gap: 8px;
    svg{
        path{
            fill: white;
            stroke: white;
        }
    }
    &.inactive{
        opacity: 0.6;
        pointer-events: none;
    }
}
.close-btn{
    position: absolute;
    right: 5px;
    top: 5px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    div{
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
        align-items: center;
        span{
            display: flex;
            position: absolute;
            height: 100%;
            width: 3px;
            background: #000000;
            border-radius: 10px;
            transform: rotate(45deg);
        }
        span:nth-child(2){
            transform: rotate(-45deg);
        }
    }
}
.progress-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    .progress-bar{
        position: relative;
        border-radius: 10px;
        height: 30px;
        width: 100%;
        overflow: hidden;
        box-shadow: 0 1px 7px 0 #d9d9d9;
        span{
            position: absolute;
            left: 0;
            top: 0;
            background-color: var(--secondary-color);
            width: 30%;
            height: 100%;
            transition: width 0.3s;
        }
    }
}
.finished-downloading{
    display: flex;
    align-items: center;
    gap: 10px;
    color: #8c8c8c;
    margin-top: 20px;
    svg{
        path{
            fill: #8c8c8c;
        }
    }
    
}
.progress-labels{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    color: #8c8c8c;
}
</style>