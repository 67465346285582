<template>
  <div :key="rerenderKey" ref="videoContainer" class="player-container" @mouseover="videoHoveringState('true')" @mouseleave="videoHoveringState('false')" :class="[{'timelineVisible': timelineVisible && options.readOnly}, {'timelineInvisible': !timelineVisible && timeoutOver && options.readOnly}]">
    <video v-if="!showDefaultPlayer" ref="videoPlayer" class="video-js vjs-default-skin" data-setup='{ "inactivityTimeout": 0 }'></video>
    <video v-else  controls>
      <source :src="options.sources[0].src" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <Bubbles 
    v-if="loaded" 
    :key="options.videoTests.length" 
    :videoPlayer="player" 
    :timeframes="options.videoTests.map(el=>{ return {video_time: (options.start_time && options.readOnly ? el.video_time - options.start_time : el.video_time), 'is_triggered': el.is_triggered}})" 
    />
    <transition name="slide" :duration="1000">
      <Subtitles v-if="subtitlesWindowOpen" :subtitleList="subtitleList" :player="player" :captionsToggled="captionsToggled" @toggleSubtitles="toggleSubtitles"/>
    </transition>
  </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import youtube from'videojs-youtube';
import offset from 'videojs-offset';
videojs.registerPlugin("offset", offset);
import Bubbles from './Bubbles.vue';
import Subtitles from './Subtitles.vue';
export default {
  name: 'VideoPlayer',
  components:{
    Bubbles,
    Subtitles,
  },
  props: {
    options: {
      type: Object,
      default() {
        return {
        };
      }
    },
    src: {
      type: String,
    }
  },
  data() {
    return {
      player: null,
      bubblesEL: null,
      loaded: false,
      bubbleUpdates: 0,
      timeSet: false,
      captionsToggled: true,
      subtitlesWindowOpen: false,
      timelineVisible: false,
      subtitleList: [],
      timeoutOver: false,
      timer: '',
      showDefaultPlayer: false,
      observer: null,
      rerenderKey: 0
    }
  },
  watch:{
    'options.videoTests':{
        handler(newVal, oldVal){
            this.bubbleUpdates++;
        },
        deep: true
        
    },
    'options.isVideoSettingsOpen':{
      handler(newVal,oldVal){
        if(newVal == true){
          this.player.addClass('vjs-sound-control-off');
        } else{
          this.player.removeClass('vjs-sound-control-off');
        }
      }
    },
    'options.ytVideoOnTabOne':{
      handler(newVal,oldVal){
        if(newVal == true){
          this.player.addClass('vjs-youtube-vid-first');;
        } else{
          this.player.removeClass('vjs-youtube-vid-first');
        }
      }
    },
    'captionsToggled':{
      handler(newVal){
        if(newVal == true){
          $('.subtitles-button').addClass('active')
        }else{
          $('.subtitles-button').removeClass('active')
        }
      }
    },
    'src': {
      immediate: true,
      handler(newSrc) {
        if (this.player) {
          window.ReactNativeWebView.postMessage("changingVideoSrc:" + newSrc);
          this.player.dispose();
          this.rerenderKey++;
          this.$nextTick(() => {
            this.initPlayer(newSrc);
          })
        }
        else {
          window.ReactNativeWebView.postMessage("changingVideoSrc1:" + newSrc);
          this.rerenderKey++;
          this.$nextTick(() => {
            this.initPlayer(newSrc);
          })
        }
        
      }
    },

  },
  mounted() {
    this.initPlayer()
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    initPlayer(src = null) {
      let vm = this;
      let refs = this.$refs;
      let options = src ? {...this.options, sources: [{ type: 'video/mp4', src: src }]} : this.options;
      this.player = videojs(this.$refs.videoPlayer, options, () => {
        vm.$emit('loaded', this.player);
          this.player.on('loadedmetadata', function(){
              vm.$emit('loadedmetadata', this.player);
              vm.loaded=true;
          })
          if(this.options.readOnly && !this.options.allowSeeking)
            this.player.controlBar.progressControl.disable();
        if(this.options.type == 'yt' && this.options.readOnly){
          let ccButton = this.player.controlBar.addChild('button', {}, 15);
          ccButton.addClass('subtitles-button');
          if(this.captionsToggled){
            ccButton.addClass('active');
          }
          ccButton.controlText(this.toLocal('generic.subtitles'));
          ccButton.el().onclick = this.loadSubs;
        }
         this.player.on('ended', function() {
          //  vm.player.controlBar.playToggle.hide(); // Hide the default play button
           console.log('Video ended');
           setTimeout(() => {
             vm.player.controlBar.playToggle.el().classList.add('vjs-ended')
             
           },200)
          // replayButton.show(); // Show the replay button
         });
        this.player.on('play', function() {
          // Remove the 'vjs-ended' class when video resumes playing
          vm.player.controlBar.playToggle.el().classList.remove('vjs-ended');
        });

        this.player.on('seeking', function() {
          // Ensure the class is removed if the user seeks in the middle of the video
          vm.player.controlBar.playToggle.el().classList.remove('vjs-ended');
        });

        this.player.on('seeked', function() {
            // Ensure the class is removed if the user seeks in the middle of the video
            vm.player.controlBar.playToggle.el().classList.remove('vjs-ended');
        });
        this.player.on('error', function(e) {
          e.stopImmediatePropagation();
          var error = this.player().error();
          vm.disposePlayer()
          vm.showDefaultPlayer = true;
        });  
      });
      this.initIntersectionObserver();
      this.player.addClass('vjs-matrix');
      if(this.options.isVideoSettingsOpen && !this.options.readOnly){
        this.player.addClass('vjs-sound-control-off');
      }
      if(this.options.type == 'yt'){
        this.player.addClass('vjs-youtube-vid-first');
      }
    },
    initIntersectionObserver() {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting) {
              this.player.pause();
            }
          });
        },
        {
          threshold: 0.1, // Adjust as needed, 0.1 means 10% of the element is visible
        }
      );
      this.observer.observe(this.$refs.videoContainer);
    },
    disposePlayer() {
      videojs(this.player).dispose();
    },
    startTimeout(){
      this.timer = setTimeout(() => {
        if(!this.timelineInvisible){
          this.timeoutOver = true;
        }
      }, 3000);
    },
    videoHoveringState(state){
      if(this.options.readOnly){
        if(state == 'true'){
          clearTimeout(this.timer);
          this.timelineVisible = true;
          this.timeoutOver = false;
        }else{
          this.timelineVisible = false;
          this.startTimeout();
        }
      }
    },
    loadSubs(){
      this.subtitlesWindowOpen = !this.subtitlesWindowOpen;
      if(this.player.tech_.ytPlayer.getOption('captions', 'tracklist')){
        this.subtitleList = this.player.tech_.ytPlayer.getOption('captions', 'tracklist');
      }
    },
    toggleSubtitles(){
      if(this.options.type=='yt'){
          if(this.subtitleList.length == 0) return;
        if(this.captionsToggled){
          this.player.tech_.ytPlayer.unloadModule('captions')
          this.player.tech_.ytPlayer.unloadModule('cc')
          this.currentLanguage = 'en';
          this.captionsToggled = false;
        }else{
          this.player.tech_.ytPlayer.loadModule('captions')
          this.player.tech_.ytPlayer.loadModule('cc')
          this.player.tech_.ytPlayer.setOption("captions", "track", {"languageCode": this.currentLanguage});

          this.captionsToggled = true;
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.subtitles-button{
  border: 1px solid red;
  position: absolute;
  bottom: 30px;
  background-color: white;
}
.player-container{
    display: flex;
    width: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    max-height: 100%;
    left: 0;
    height: 100%;
    .subtitles-container{
      opacity: 0;
      transition: 0.25s;
    }
    &:hover{
      .subtitles-container{
        opacity: 1;
      }
    }
}
</style>
<style lang="scss">
.player-container{
    .video-js{
        height: 100%;
        max-height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        z-index: auto;
    }
    .vjs-youtube-vid-first .vjs-control-bar{
          opacity: 0.0;
          transition: 0.25s;
        }
        &:hover{
          .vjs-youtube-vid-first .vjs-control-bar{
            opacity: 1;
          }
    }
}
.vjs-custom-timestamp-bubbles {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  width: 30px;
  background: red;
  pointer-events: none;
}

.vjs-matrix.video-js {
  .vjs-slider-bar{
    color: var(--secondary-color);
    background-color: var(--secondary-color);
    z-index: 2;
    &::before{
      z-index: 2;
    }
  }
}
.timelineVisible{
  .vjs-control-bar{
    opacity: 1;
  }
}
.timelineInvisible{
  .vjs-control-bar{
    opacity: 0;
  }
}
.video-js{
  .vjs-control-bar{
  height: 4em;
    .subtitles-button{
      &::before{
        transition: 0.25s;
        border-radius: 3px;
        border: 1px solid white;
        padding: 2px;
        content: "CC";
        cursor: pointer;
      }
      &.active{
        &::before{
          background-color: white;
          color: #333333;
        }
      }
    }
}
}
.video-js .vjs-control{
  height: 80%;
}
.vjs-sound-control-off .vjs-subs-caps-button, .vjs-sound-control-off .vjs-playback-rate, .vjs-sound-control-off .vjs-volume-panel{
  display: none;
}
.vjs-sound-control-off .vjs-playback-rate{
  display: none;
}

.video-js .vjs-progress-control .vjs-mouse-display{
  bottom: 0px;
}
.video-js .vjs-time-tooltip{
  top: -50px;
}


ul {
   cursor: pointer;
}

.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 150px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>