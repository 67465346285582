<template>
  <div
    id="app"
    :class="{
      'header-hidden':
        $store.state.header_hidden ||
        !$store.state.isLoggedIn ||
        !$store.state.header ||
        !$store.state.header_enabled,
    }"
    :key="$store.state.pageRerenderKey"
  >
    <!-- <UpdateModal v-if="$store.state.checkAutoUpdate"/> -->
    <div v-if="$store.state.alertMessages.length > 0" class="alert">
      <span
        v-for="(msg, msgIndex) in $store.state.alertMessages"
        :key="msgIndex"
      >
        {{ msg }}<br />
      </span>
    </div>
    <LoadingScreen ref="loading" />
    <CookieModal />
    <InfoModal />
    <FirstLoginModal
      v-if="$store.state.user_is_new == 1 && $route.name != 'privacyPolicy'"
    />
    <TrainingCommentsSide
      v-if="$store.getters.isCurrentSide('training-comments-side')"
    />
    <Header v-show="$store.state.isLoggedIn" ref="header" />
    <Toast ref="toast" />
    <Menu v-show="$store.state.isLoggedIn" />
    <!-- <Terms v-show="$store.state.isLoggedIn" /> -->
    <ChatSide />
    <!-- <ChangePassword v-if="$store.state.user_is_new == 1" /> -->
    <Certificates ref="certificates" v-show="$store.state.isLoggedIn" />
    <DocumentSide v-show="$store.state.isLoggedIn" />
    <PrivacyPolicy v-if="$store.state.privacy_policy_modal" />
    <item-preview-modal/>
    <section
      v-if="loaded"
      :class="{
        'router--wrapper': true,
        [$route.name+'-view']: true,
        'header--hidden': !$store.state.header,
        'header--disabled': !$store.state.header_enabled,
        'header--temp--hidden': $store.state.header_hidden,
        'non-logged-in': !$store.state.isLoggedIn,
        'preview-mode': $store.state.reportForming.previewMode,
      }"
    >
      <transition name="fade" mode="out-in">
        <keep-alive
          :include="
            $store.state.user_id == null ? [] : keepAliveComponents
          "
        >
          <router-view />
        </keep-alive>
      </transition>
    </section>
    <!-- <div id="testsa" class="test-imgs" >

    </div> -->
    <Footer v-if="$route.meta && $route.meta.showFooter && loaded && systemSettings.showFooter" />
    <!-- <portal-target name="signature-editor"></portal-target> -->
  </div>
</template>

<script>
import * as Sentry from "@sentry/browser";
import Header from "./components/layout/Header.vue";
import Menu from "./components/layout/Menu.vue";
import Terms from "./components/layout/Terms.vue";
import LoadingScreen from "./components/layout/LoadingScreen.vue";
import axios from "axios";
import ChatSide from "./components/layout/users/ChatSide.vue";
import DocumentSide from "./components/layout/users/DocumentSide.vue";
import Certificates from "./components/layout/users/Certificates.vue";
import ChangePassword from "./components/layout/ChangePassword.vue";
import Toast from "./components/layout/Toast.vue";
import FirstLoginModal from "./components/layout/FirstLoginModal.vue";
import CookieModal from "./components/layout/CookieModal.vue";
import PrivacyPolicy from "./components/layout/PrivacyPolicy.vue";
import InfoModal from "./components/layout/errorHandling/InfoModal.vue";
import AssignModal from "./components/layout/modals/AssignModal.vue";
import Footer from "./components/layout/Footer.vue";
import TrainingCommentsSide from "@/components/layout/trainings/TrainingCommentsSide";
import ItemPreviewModal from "@/views/ItemPreviewModal";

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

export default {
  name: "App",
  components: {
    ChatSide,
    DocumentSide,
    Header,
    Menu,
    LoadingScreen,
    Terms,
    Certificates,
    Toast,
    ChangePassword,
    CookieModal,
    FirstLoginModal,
    PrivacyPolicy,
    InfoModal,
    AssignModal,
    Footer,
    ItemPreviewModal,
    TrainingCommentsSide,
  },
  data() {
    return {
      loaded: false,
      isIdling: false,
      keepAliveComponents: [
        "Settings",
        "Email",
        "Hierarchy",
        "Login",
        "Register",
        "Groups",
        "Team",
      ],
      imgTest: '',
      testFile: '',
      logs: '',
    };
  },
  watch: {
    $route(to, from) {
      if (to.path != "/email") {
        setTimeout(() => {
          this.$store.state.side_open = "";
        }, 300);
      }

      if (window.isNative) {
        window.ReactNativeWebView.postMessage("routeChange:" + to.path);
      }
      if (["/edit-training", "/edit-group", "/edit"].includes(to.path)) {
        this.$store.state.current_menu_item = 1;
      }
      if (to.path == "/home" || to.path == "/") {
        this.$store.state.current_menu_item = 2;
      }
      if (to.path == "/groups") {
        this.$store.state.current_menu_item = 3;
      }
      if (to.path == "/users") {
        this.$store.state.current_menu_item = 4;
      }
      if (to.path == "/email") {
        this.$store.state.current_menu_item = 5;
      }
      if (to.path == "/settings") {
        this.$store.state.current_menu_item = 6;
      }

      this.$store.state.header = to.path != "/login";

      this.$store.state.header_hidden =
        to.path == "/edit-training" || to.path == "/training";
    },
    '$store.state.pageRerenderKey'() {
      this.loaded = false
      this.initialSetup()
    },
    "$store.state.system_settings": {
      immediate: true,
      deep: true,
      handler: function () {
        document.documentElement.style.setProperty(
          `--primary-color`,
          this.$store.state.system_settings.primary_color
        );
        document.documentElement.style.setProperty(
          `--secondary-color`,
          this.$store.state.system_settings.secondary_color
        );
        document.documentElement.style.setProperty(
          `--third-color`,
          this.$store.state.system_settings.third_color
        );
        document.documentElement.style.setProperty(
          `--primary-color-opacity-50`,
          this.$store.state.system_settings.primary_color + "80"
        );
        document.documentElement.style.setProperty(
          `--secondary-color-opacity-50`,
          this.$store.state.system_settings.secondary_color + "80"
        );
        if (this.$store.state.system_settings.secondary_color) {
          let hsl = this.hexToHSL(
            this.$store.state.system_settings.secondary_color
          );
          document.documentElement.style.setProperty(
            `--theme-hue`,
            hsl["h"] * 360
          );
          document.documentElement.style.setProperty(
            `--theme-saturation`,
            parseInt(hsl["s"] * 100) + "%"
          );
          let lightness = parseInt(hsl["l"] * 100);
          if (lightness < 65) lightness = 65;
          if (lightness > 85) lightness = 85;
          document.documentElement.style.setProperty(
            `--theme-lightness`,
            lightness + "%"
          );
        }
      },
    },
    "$store.state.user_id"() {
      if (this.$store.state.user_id) {
        this.getGroups();
        this.getDepartments();
      }
    },
    "$store.state.websockets"() {
      if (
        this.$store.state.user_id != null &&
        this.$store.state.websockets != null
      ) {
        this.startChatEchoListen();
        this.startNotificationEchoListen();
      }
    },
    "$store.state.currentTraining"(newVal, oldVal) {
      if (newVal == -1) {
        this.$store.state.currentTrainingData = null;
      }
    },
  },
  async mounted() {
    
    await this.initialSetup();
  },
  onIdle() {
    this.isIdling = true;
  },
  onActive() {
    if (this.isIdling) {
      window.location.reload();
      this.isIdling = false;
    }
  },
  methods: {
    async initialSetup() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (userAgent.includes('LKakademija-App')) {
        window.isNative = true
      }
      if (window.isNative) {
        axios.defaults.withCredentials = false;
        this.$store.state.isNative = true;
      }
      document.documentElement.style.setProperty(
        `--main-site-font`,
        "'Noto Sans', sans-serif"
      );
      //console.log(this.checkColorContrast('#FFFFFF', this.$store.state.system_settings.primary_color));
      this.$store.state.loading = this.$refs.loading;
      this.$store.state.toast = this.$refs.toast;
      this.getLanguage();
      this.$store.commit("fetchTranslations");
      await this.$store.dispatch("fetchSystemSettings");
      this.$store.state.certificates = this.$refs.certificates;
      this.$store.state.window_width = window.innerWidth;
      window.addEventListener("resize", () => {
        this.$store.state.window_width = window.innerWidth;
      });
      this.$store.state.loading.loadingStart();
      if (this.$route.params.token && ['login', 'login-token'].includes(this.$route.name)) {
        await this.loginWithToken();
      } else {
        if (this.$store.state.isNative) {
          await this.setUserNative();
        }
        else {
          await this.setUser();
        }
      }
      this.$nextTick(() => {
        this.$refs.loading.loadingDone();
      });
      if (window.ReactNativeWebView) {
        this.checkForUpdates();
      }
      // axios
      //   .get('/version.json', {
      //     headers: {
      //       'Cache-Control': 'no-cache',
      //       'Pragma': 'no-cache',
      //       'Expires': '0',
      //     }
      //   })
      //   .then((response) => {
      //     this.$store.state.appVersion = response.data.version;
      //   });
    },
    logCurrentSrc(event) {
        alert(event.target.currentSrc)
    },
    setWebViewData(data) {
      if (data.data.startsWith('fileDownloaded:')) {
        const message = data.data;
        let fileUri = message.replace('fileDownloaded:', '');
      }
    },
    checkForUpdates() {
      axios
        .get('/version.json', {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          }
        })
        .then((response) => {
          let version = response.data.version;
          this.$store.state.appVersion = version;
          window.ReactNativeWebView.postMessage("checkForUpdates:" + version);
        });
    },
    async setUserNative() {
      if (
        window.localStorage.getItem("access_token") != "null" ||
        window.sessionStorage.getItem("access_token") != "null"
      ) {
        if (window.localStorage.getItem("access_token") != "null") {
          this.$store.state.access_token =
            window.localStorage.getItem("access_token");
        } else {
          this.$store.state.access_token =
            window.sessionStorage.getItem("access_token");
        }
        await this.setUser();
      }
    },
    async setUser() {
        axios.get(
          `${this.$store.state.api_link}/user`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.platform_to_switch) {
            this.$store.state.loading.loadingStart()
            this.setNewUrl(response.data.platform_to_switch)
            this.$store.state.loading.loadingDone()
            this.$store.state.pageRerenderKey++;
            return;
          }
          this.loaded = true;
          if (Object.keys(response.data).length === 0) {
            if (!(this.$route.meta && this.$route.meta.nonRedirectable))
              this.$router.push("/login");
          } else {
            if(response.data.backend_domain){
              this.setNewUrl(response.data.backend_domain)
            }
            if (response.data.connected_lmses) {
              this.$store.state.connectedLMSes = response.data.connected_lmses
            }
              if (response.data.current_platform) {
              this.$store.state.currentLMS = response.data.current_platform
            }
            if (response.data.managed_groups) {
              this.$store.state.managedGroups = response.data.managed_groups
            }
            // if (!(this.$route.meta && this.$route.meta.nonRedirectable ))
            //     this.$router.push("/");
            this.$store.state.user_email = response.data.email;
            Sentry.setUser({ email: response.data.email });
            this.$store.state.user_id = response.data.id;

            this.$store.state.user_name = response.data.name;
            this.$store.state.user_last_name = response.data.last_name;
            this.$store.state.user_role = response.data.role;
            this.$store.state.user_permissions = response.data.permissions;
            this.$store.state.user_is_new = response.data.new_user;
            this.$store.state.user_trainings_started =
              response.data.trainings_started;
            this.$store.state.user_trainings_ended =
              response.data.trainings_ended;
            this.$store.state.user_test_average = response.data.test_average;
            this.$store.state.user_username = response.data.username;
            if (response.data.phone)
              this.$store.state.user_phone = response.data.phone;
            this.$store.state.user_can_contact = response.data.can_contact;
            this.$store.state.user_can_notify = response.data.can_notify;
            this.$store.state.user_can_identify = response.data.can_identify;
            this.$store.state.user_selected_language =
              response.data.selected_language;
            this.$store.state.language =
              response.data.selected_language != null
                ? response.data.selected_language
                : this.$store.state.language;
            this.$store.state.certificates.getCertificates();
            this.$store.commit("checkTerms", {
              getters: this.$store.getters,
            });
            this.$store.commit("startWebsockets");
            this.$store.commit("userLoggedIn");
            this.$store.commit("initializeUserCols");
            this.$store.commit("loadUserInfo");

          }
        })
        .catch((error) => {
          if (!(this.$route.meta && this.$route.meta.nonRedirectable)) {
            if (this.$store.state.initial_api_link && !this.$store.state.api_link.includes(this.$store.state.initial_api_link)){
              this.$store.state.loading.loadingStart()
              this.setNewUrl(this.$store.state.initial_api_link)
              this.$store.state.loading.loadingDone()
              this.$store.state.pageRerenderKey++;
            }
            this.$router.push("/login");
            this.$store.commit("handleError", { error: error });
          }
        
          if (window.isNative) {
            window.localStorage.setItem("access_token", null);
            window.sessionStorage.setItem("access_token", null);
          }
          this.loaded = true;
        });
     
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    async loginWithToken() {
      axios
        .post(`${this.$store.state.api_link}/login`, {
          registration_token: this.$route.params.token,
        })
        .then((res) => {
          if (res.data.status == "error") {
            this.$router.push("/login");
            return;
          }
          this.errors = [];
          // this.$store.state.access_token = res.data.token.original.access_token;
          this.$store.state.user_email = res.data.user.email;
          Sentry.setUser({ email: res.data.user.email });
          this.$store.state.user_id = res.data.user.id;

          this.$store.state.user_name = res.data.user.name;
          this.$store.state.user_last_name = res.data.user.last_name;
          this.$store.state.user_role = res.data.user.role;
          this.$store.state.user_is_new = res.data.user.new_user;
          this.$store.state.user_trainings_started =
            res.data.stats.trainings_started;
          this.$store.state.user_trainings_ended =
            res.data.stats.trainings_ended;
          this.$store.state.user_test_average = res.data.stats.test_average;
          this.$store.state.user_username = res.data.user.username;
          this.$store.state.user_selected_language =
            res.data.user.selected_language;
          this.$store.state.language =
            res.data.user.selected_language != null
              ? res.data.user.selected_language
              : this.$store.state.language;
          if (res.data.user.phone)
            this.$store.state.user_phone = res.data.user.phone;
          this.$store.state.user_can_contact = res.data.user.can_contact;
          this.$store.state.user_can_notify = res.data.user.can_notify;
          this.$store.state.user_can_identify = res.data.user.can_identify;

          // window.localStorage.setItem("access_token", null);
          // window.sessionStorage.setItem(
          //   "access_token",
          //   res.data.token.original.access_token
          // );
          if (res.data.token.original.new_user == 1) {
            this.selectNewGroup = true;
            window.scrollTo(0, 0);
            this.$router.push("/");
          } else {
            this.$router.push("/");
          }
          this.$store.state.certificates.getCertificates();
          this.$store.commit("checkTerms", { getters: this.$store.getters });
          this.$store.commit("startWebsockets");
          this.$store.commit("userLoggedIn");
          this.loaded = true;

          // window.localStorage.setItem("access_token", null);
          // window.sessionStorage.setItem(
          //   "access_token",
          //   res.data.token.original.access_token
          // );
          if (res.data.token.original.new_user == 1) {
            this.selectNewGroup = true;
            window.scrollTo(0, 0);
            this.$router.push("/");
          } else {
            this.$router.push("/");
          }
          this.$store.state.certificates.getCertificates();
          this.$store.commit("checkTerms", { getters: this.$store.getters });
          this.$store.commit("startWebsockets");
          this.$store.commit("userLoggedIn");
          this.$store.commit("initializeUserCols");
          this.loaded = true;
        })
        .catch((err) => {
          this.$router.push("/login");
          this.loaded = true;
        });
    },
    setNewUrl(backend_url) {
        this.$store.state.api_link = `${this.$store.state.ssl ? "https" : "http"}://${
            backend_url
        }/api`;
        this.$store.state.api_uploads_link = `${this.$store.state.ssl ? "https" : "http"}://${
            backend_url
        }/uploads/`;
          this.$store.state.api_assets_link = `${this.$store.state.ssl ? "https" : "http"}://${
            backend_url
        }/assets/`;
          this.$store.state.backend_link = `${this.$store.state.ssl ? "https" : "http"}://${
            backend_url
            }`;
        this.$store.commit("fetchSystemSettings");
    },
    fetchUnreadChats() {
      if (this.$store.state.user_email == null) return;
      axios
        .get(
          `${this.$store.state.api_link}/chat/chats/unread`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.status == "success") {
            this.$store.state.unread_chats = response.data.chats;
          } else {
            console.log("Error while trying to fetch unread chats");
            this.$store.commit("handleError", {
              error: "Error while trying to fetch unread chats",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("handleError", { error: error });
        });
    },
    getGroups() {
      axios
        .get(
          `${this.$store.state.api_link}/group/groups-min`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.$store.state.groups = response.data;
        })
        .catch((error) => {});
    },
    getDepartments() {
      axios
        .get(
          `${this.$store.state.api_link}/hierarchy/nodes`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.$store.state.departments = response.data.data;
        })
        .catch((error) => {});
    },
    startChatEchoListen() {
      if (this.$store.state.user_role == "admin") {
        window.Echo.private(
          `${this.$store.state.system_settings.lms_key}.chat.admins`
        ).listen("AdminChatMessages", (e) => {
          this.$store.state.unread_chats.push(e.chat_id);
          this.$store.state.chatMessagesQueue.push(e.message);
        });
      } else {
        window.Echo.private(
          `${this.$store.state.system_settings.lms_key}.chat.${this.$store.state.user_id}`
        ).listen("SendChatMessage", (e) => {
          this.$store.state.unread_chats.push(e.chat_id);
          this.$store.state.chatMessagesQueue.push(e.message);
        });
      }
    },
    startNotificationEchoListen() {
      if (this.$store.state.user_role == "admin") {
        window.Echo.private(
          `${this.$store.state.system_settings.lms_key}.notifications.${this.$store.state.user_id}`
        ).listen("NotifyUser", (e) => {
          let notif = e.data;
          if (notif.type == "user_import_progress") {
            this.$root.$emit("user_import_progress", notif.data);
          } else if (notif.type == "user_import") {
            this.$root.$emit("user_import", notif);
          }
        });
      }
    },
    hexToHSL(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (result == null) return;
      let r = parseInt(result[1], 16);
      let g = parseInt(result[2], 16);
      let b = parseInt(result[3], 16);
      (r /= 255), (g /= 255), (b /= 255);
      var max = Math.max(r, g, b),
        min = Math.min(r, g, b);
      var h,
        s,
        l = (max + min) / 2;
      if (max == min) {
        h = s = 0; // achromatic
      } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
        }
        h /= 6;
      }
      var HSL = new Object();
      HSL["h"] = h;
      HSL["s"] = s;
      HSL["l"] = l;
      return HSL;
    },
    getLanguage() {
      let lang = this.$cookies.get("selected_language");
      if (lang != null) {
        this.$store.state.language = lang;
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,900&display=swap");
@import "./assets/fonts/stylesheet.css";
@import "./assets/scss/_variables.scss";
@import "./assets/scss/_transitions.scss";

:root {
  --primary-color: #f7f7f7;
  --secondary-color: #f7f7f7;
  --third-color: #f7f7f7;
  --primary-color-opacity-50: #f7f7f7;
  --secondary-color-opacity-50: #f7f7f7;
  --theme-hue: 0;
  --theme-saturation: 0;
  --theme-lightness: 0;
}
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding-top: 11vh;
  &.header-hidden {
    padding-top: 0;
  }
}
html,
body {
  margin: 0;
  font-family: var(--main-site-font);
  font-size: 20px;
  color: $font_color;
  background-color: #efefef;
}
.grecaptcha-badge {
  visibility: hidden;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-family: var(--main-site-font);
  font-weight: normal;
  font-size: 40px;
}

h2 {
  font-family: var(--main-site-font);
  font-weight: normal;
  font-size: 30px;
}

.clear {
  clear: both;
}

.alert {
  position: fixed;
  top: 20px;
  right: 20px;
  font-family: var(--main-site-font);
  font-size: 24px;
  text-align: right;
  color: red;
  font-weight: 900;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: none;

  // @media (max-width: 768px) {
  // display: none;
  // }
}
.tooltip-container {
  display: flex;
  background: white;
  border: 1px solid var(--primary-color);
  padding: 5px 10px;
  z-index: 99;
  .tooltip-inner {
    color: var(--primary-color);
    border-radius: 16px;
  }

  .tooltip-arrow {
    width: 10px;
    height: 10px;
    background: white;
    border-style: solid;
    position: absolute;
    // margin: 5px;
    transform: rotate(45deg);
    border: 1px solid black;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      // border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      // border-right-color: transparent !important;
      border-top-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      // border-width: 0 5px 5px 5px;
      // border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      // border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      // border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      // border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      // border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
}
.app--wrapper {
  height: 100vh;
  width: 100%;
  background-color: #efefef;
}

.router--wrapper {
  left: 0px;
  padding-left: 4vw;
  top: 105px;
  width: 100%;
  // min-height: 100vh;
  // height: 100%;
  min-height: 100vh;
  transition: 0.5s top;

  &.header--hidden {
    left: 0;
    top: 0;
    padding-left: 0;
    width: 100%;
    height: 100vh;
  }

  &.header--disabled {
    top: 0;
    min-height: 100vh;
  }

  &.header--temp--hidden,
  &.preview-mode {
    top: 0;
  }
  &.non-logged-in {
    padding-left: 0;
  }
  &.edit-group-view{
    min-height: 89vh;
  }
  &.edit-view{
    min-height: 89vh;
  }
  @media (max-width: 768px) {
    background-color: #efefef;
    padding-left: 0px;
  }
}
.group-leader-view{
  max-height: calc(100vh - 11vh);
  min-height: calc(100vh - 11vh);
}
.container {
  max-width: 1140px;
  width: 95%;
  margin: auto;
  position: relative;
  font-family: var(--main-site-font);

  &.wide--container {
    max-width: none;
    width: 100%;
  }
}
.test-img{
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  z-index: 1000;
  background: #00000045;
}
.test-imgs{
   position: absolute;
  top: 0;
  z-index: 1000;

}
.table-control {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;

  thead {
    th {
      font-weight: normal;
      font-size: 16px;

      &.large {
        font-size: 30px;
        font-weight: 500;
      }
    }
  }

  tbody {
    tr {
      &:first-child {
        margin-top: 10px;
      }
    }

    td {
      background: #fff padding-box;

      img {
        vertical-align: middle;
      }

      &.centered {
        text-align: center;
      }

      &.large {
        font-size: 1.35em;
      }

      &.actions {
        img {
          padding: 0 10px;
          cursor: pointer;
          vertical-align: middle;
        }
        svg {
          margin: 0 8px;
          cursor: pointer;
          vertical-align: middle;
          overflow: hidden;

          &:hover {
            &.stroke path,
            &.stroke line,
            &.stroke ellipse {
              stroke: #6d66a2;
              &.hover-fill {
                fill: #6d66a2;
              }
            }
          }
        }
      }

      // &.padded {
      //   padding: 15px 20px;
      // }

      .border-left {
        border-left: 1px solid #cacaca;
        height: 30px;
        max-width: 600px;
        // min-width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.side--table--control {
  width: calc(100% + 70px);
  border-collapse: separate;
  border-spacing: 0 10px;
  margin: 0 -35px;

  thead {
    th {
      font-weight: normal;
      font-size: 16px;

      &.large {
        font-size: 30px;
        font-weight: 500;
      }
    }
  }

  tbody {
    tr {
      transition: 1s background-color;

      &:first-child {
        margin-top: 10px;
      }
    }

    td {
      // background-color: #EFEFEF;

      img {
        vertical-align: middle;
      }

      &.centered {
        text-align: center;
      }

      &.large {
        font-size: 1.35em;
      }

      &.actions {
        img {
          padding: 0 10px;
          cursor: pointer;
        }
        svg {
          cursor: pointer;
          vertical-align: middle;
          margin: 0 3px;
        }
      }

      // &.padded {
      //   padding: 15px 20px;
      // }

      .border-left {
        border-left: 1px solid #cacaca;
        width: 100%;
        height: 30px;
        padding: 0 3px;
        margin-left: -1px;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: rgb(225, 225, 225);
}
::-webkit-scrollbar-thumb {
  background: rgb(190, 190, 190);
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(151, 151, 151);
}

@media (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.ProseMirror {
  outline: none;
}

// @for $i from 0 through 14 {
//   .gc-#{$i}, .gc-td-#{$i} td {
//     background-color: hsl((3 + ($i - 1) * 24), 100%, 76%);
//   }
//   .gtc-#{$i} {
//     td{
//       background-color: hsl((3 + ($i - 1) * 24), 100%, 76%) !important;
//     }
//   }
//   .bc-#{$i}, .bc-#{$i} td {
//     border-bottom: 4px solid hsl((3 + ($i - 1) * 24), 100%, 76%);
//   }
// }
@for $i from 0 through 14 {
  .gc-#{$i},
  .gc-td-#{$i} td {
    background-color: hsl(
      ($i * 40 % 360),
      (20 + 2.5 * $i) % 70 * 1%,
      var(--theme-lightness)
    );
  }
  .gc-#{$i}-opacity-50 {
    background-color: hsla(
      ($i * 40 % 360),
      (20 + 2.5 * $i) % 70 * 1%,
      var(--theme-lightness),
      50%
    );
  }
  .gtc-#{$i} {
    td {
      background-color: hsl(
        ($i * 40 % 360),
        (20 + 2.5 * $i) % 70 * 1%,
        var(--theme-lightness)
      ) !important;
    }
  }
  .bc-#{$i},
  .bc-#{$i} td {
    border-bottom: 4px
      solid
      hsl(($i * 40 % 360), (20 + 2.5 * $i) % 70 * 1%, var(--theme-lightness));
  }
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.fa-spinner {
  animation: spinner 1s linear infinite;
}

.vc-title {
  color: black !important;
  padding: 0 1rem !important;
  width: 100% !important;
  font-weight: 900 !important;
  text-align: center !important;
  font-size: 16px !important;
  line-height: initial !important;
  margin-bottom: 5px !important;
}

.vc-text {
  color: black !important;
  padding: 0 1rem !important;
  width: 100% !important;
  font-weight: 500 !important;
  text-align: center !important;
  font-size: 14px !important;
  line-height: initial !important;
}

.vc-overlay {
  background: rgba(0, 0, 0, 0.29);
  width: 100%;
  height: 100%;
  transition: all 0.1s ease-in;
  left: 0;
  top: 0;
  z-index: 999999999999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: baseline;
}

.vc-container {
  background: white;
  border-radius: 1rem;
  width: 286px;
  height: auto;
  display: grid;
  grid-template-rows: 1fr auto;
  box-shadow: rgba(0, 0, 0, 0.29) 0px 3px 8px 0px;
}

.vc-text-grid {
  padding: 1rem;
}

.vc-btn-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 50px;
  border-radius: 0 0 1rem 1rem;
  overflow: hidden;
}

.vc-btn-grid.isMono {
  grid-template-columns: 1fr;
}

.vc-btn {
  border-radius: 0 0 1rem 0;
  color: cornflowerblue;
  background: white;
  border: 0;
  font-size: 1rem;
  border-top: 1px solid rgb(224, 224, 224);
  cursor: pointer;
  font-weight: 700;
  outline: none;
}

.vc-btn:hover {
  background: whitesmoke;
}

.vc-btn:disabled {
  background: whitesmoke;
}

.vc-btn:active {
  box-shadow: inset 0 2px 0px 0px #00000014;
}

.vc-btn.left {
  border-radius: 0;
  /* color: black; */
  border-right: 1px solid #e0e0e0;
}

.vc-input[type="password"] {
  width: 100%;
  outline: none;
  border-radius: 8px;
  height: 35px;
  border: 0;
  margin: 5px 0;
  background-color: #ebebeb;
  padding: 0 0.5rem;
  font-size: 16px;
  transition: 0.21s ease;
}

.vc-input[type="password"]:hover,
.vc-input[type="password"]:focus {
  background-color: #dfdfdf;
}

.tooltip--wrapper {
  .tooltip {
    position: absolute;
    background-color: #fff;
    font-size: 0.6vw;
    color: #464646;
    margin-top: -3.4vw;
    margin-left: -1.5vw;
    padding: 0.8vw;
    border: 0.12vw solid #464646;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    transform: scale(0);
    transition: 0.3s all;
    transform-origin: 25% 120%;
    letter-spacing: 1px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0.8vw;
      top: 100%;
      width: 0;
      height: 0;
      border: 0.75vw solid transparent;
      border-top-color: #464646;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0.95vw;
      top: 100%;
      width: 0;
      height: 0;
      border: 0.6vw solid transparent;
      border-top-color: #fff;
    }
  }
  &:hover {
    .tooltip {
      opacity: 1;
      transform: scale(1);
    }
  }
}

@media (max-width: 768px) {
  * {
    // user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
}
.blackContrast{
  color: #333;
  .icon path{
    fill: #333;
  }
  &.hoverable{
    &:hover{
      background-color: #4D4D4D;
      .icon path{
        fill: white;
      }
    }
  }
}
.whiteContrast{
  color: white;
  path{
    // fill: white;
  }
  .checkmark{
    path{
      fill: initial;
      stroke: white
    }
  }
  &.hoverable{
    &:hover{
      background-color: white;
      path{
        fill: #333;
      }
    }
  }
}
#video-container{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: gray;
  min-width: 350px;
  min-height: 196px;
  video{
    background: #f27e7e;
  }
}

</style>
